import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
// import HomeBannerImg from "../../../assets/home-banner.png";
import Video from "../../../assets/even-derech.mp4";

function HomeBanner() {
    // useEffect(() => {
    //     const video = document.getElementById("main-video");
    //     video.addEventListener('ended', videoEnded);

    //     return () => {
    //         video.removeEventListener('ended', videoEnded);
    //     }
    // }, [])

    // const videoEnded = (event) => {
    //     const video = event.target;
    //     video.play();
    // }

    return (
        
        <div className="home-banner text-white">

            <div className="video-container">
                <video id="main-video" src={Video} type="video/mp4" width="45%" height="100%" autoPlay loop muted></video>
            </div>


            <div className="home-banner__data">
                <div className="container d-flex align-items-center">
                    <div className="home-banner__text">
                        <h1 className="lh-1">״הלקוח במרכז״</h1>
                        <h1 className="mt-3 h5 fw-lighter lh-base ps-5">
                            <span className="d-block">
                                בעידן בו אנחנו חיים יש משמעות גדולה לתפיסת הלקוח ויצירת גמישות מחשבתית מתאימה.
                            </span>
                            <span className="d-block pt-3">שירות איכותי ללקוח הינו נקודת המוצא שעמדה בפנינו כאשר הקמנו את אבן דרך.</span>
                        </h1>

                        <NavLink className="btn btn-light mt-4 px-4 align-text-left" to="/contact-us">לפרטים נוספים ויצירת קשר</NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeBanner